<template>
  <div class="announce-campaign-detail-container root mt-3">
    <div class="banner mb-3">
      <img
        :src="data.imageUrl"
        height="180px"
        width="100%"
      />
    </div>
    <div class="mt-2">
      <b>{{data.title}}</b>
      <div v-html="data.description"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  mounted() {
    window.loading(false);
  },
};
</script>

<style lang="scss" scoped>
.root {
  color: #2b2b2b;
  min-height: 100vh;
  padding: 0px 20px 10px;
}
</style>